import React, { useMemo } from "react";
import _ from "lodash";
import { Button, Icon } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { useSelector } from "react-redux";
import { RootState } from "@/Store";
import { IFilterAggregate } from "@/Interfaces/LogTime.interface";
import dayjs from "dayjs";
import { ENUMS } from "@/Constants";
import Utils from "@/Utils";
import { useTranslation } from "react-multi-lang";

type Props = {
  //  csvData: any[];
  //  interval: string;
};

const headerExceljs = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

export const ExportToExcelOvertime: React.FC<Props> = (
  {
    //  csvData,
    //  interval,
  }
) => {
  const t = useTranslation();
  const aggregateList: any = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "aggregateList")
  );

  const paginationAgregate: IFilterAggregate = useSelector((state: RootState) =>
    _.get(state.LOG_TIME, "paginationAgregate")
  );

  const workingTimeList: any[] = useSelector((state: RootState) =>
    _.get(state.WORKING_TIME, "workingTimeList")
  );

  const checkCurrentimeMorning =
    workingTimeList[0]?.morningStart &&
    Utils.convertToTime(workingTimeList[0]?.morningStart);

  const titleCase = (string: string) => {
    let sentence = string.toLowerCase().split(",");

    for (let i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(",");
  };

  const transformData = (aggregateList: any[]) => {
    const data: any[] = [];
    _.forEach(aggregateList, (item) => {
      let totalWeekend: number = 0;
      let totalWeekday: number = 0;
      let totalHoliday: number = 0;
      let totalLateHour: number = 0;
      let role: string[] = [];
      _.map(item.logTime, (entry) => {
        if (
          entry.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
          entry.startTime >= checkCurrentimeMorning
        ) {
          totalWeekday += _.toNumber(entry.workingTime);
        }
        if (entry.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY) {
          totalHoliday += _.toNumber(entry.workingTime);
        }
        if (entry.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND) {
          totalWeekend += _.toNumber(entry.workingTime);
        }
        if (
          entry.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
          entry.startTime < checkCurrentimeMorning
        ) {
          totalLateHour += _.toNumber(entry.workingTime);
        }
      });

      _.forEach(item?.userRole, (entry) => {
        if (entry?.role?.roleCode === "artist") {
          role.push("Staff");
        } else role.push(entry?.role?.roleCode);
      });
      const employeeData: any = {
        fullName: item?.userData?.fullName,
        StaffCode: item?.staffCode,
        role: titleCase(role.toString()),
        weekend: totalWeekend * 1,
        holiday: totalHoliday * 1,
        totalWeekday: totalWeekday * 1,
        totalLateHour: totalLateHour * 1,
        total:
          totalWeekday * 1 +
          totalHoliday * 1 +
          totalWeekend * 1 +
          totalLateHour * 1,
      };
      data.push(employeeData);
    });
    return data;
  };

  const transformedData = useMemo(() => {
    return transformData(aggregateList);
  }, [aggregateList, checkCurrentimeMorning]);

  const summary = useMemo(() => {
    let sum: any = 0;
    _.forEach(transformedData, (item: any) => (sum += _.toNumber(item.total)));
    return sum;
  }, [transformedData]);

  async function exportToExcel() {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");
    worksheet.getColumn(1).width = 10;
    worksheet.getColumn(2).width = 25;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 20;
    worksheet.getColumn(5).width = 20;
    worksheet.getColumn(6).width = 20;
    worksheet.getColumn(7).width = 20;
    worksheet.getColumn(8).width = 20;
    worksheet.getColumn(9).width = 15;

    // Add new rows at the beginning
    worksheet.getCell("A1").value = "Type";
    worksheet.getCell("B1").value = "overtime";

    //title
    worksheet.getCell("A2").value = t("title.overtimesStatistics");
    worksheet.mergeCells("A2:I2");

    worksheet.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A2").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(2).height = 30;
    // year
    worksheet.getCell("A3").value = `${dayjs(
      paginationAgregate?.startDate
    ).format("DD/MM/YYYY")} - ${dayjs(paginationAgregate?.endDate).format(
      "DD/MM/YYYY"
    )}`;
    worksheet.mergeCells("A3:I3");
    worksheet.getCell("A3").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet.getCell("A3").font = {
      size: 13,
      bold: false,
      color: { argb: "FFFFFF" },
    };
    worksheet.getCell("A3").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet.getRow(3).height = 20;

    //header
    worksheet.addRow([
      `${t("table.no")}`,
      `${t("table.fullName")}`,
      `${t("table.staffCode")}`,
      `${t("table.role")}`,
      `${t("table.weekend/Hour")}`,
      `${t("table.holiday/Hour")}`,
      `${t("table.beforeHours")}`,
      `${t("table.afterHours")}`,
      `${t("table.totalHour")}`,
    ]);

    _.forEach(headerExceljs, (i) => {
      worksheet.getCell(`${i}4`).border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    //data
    transformedData.forEach((item, index) => {
      worksheet.addRow([
        index + 1,
        item?.fullName,
        item?.StaffCode,
        item?.role,
        item?.weekend,
        item?.holiday,
        item?.totalWeekday,
        item?.totalLateHour,
        item?.total,
      ]);

      _.forEach(headerExceljs, (i) => {
        worksheet.getCell(`${i}${index + 4}`).border = {
          top: { style: "thin", color: { argb: "000000" } },
          left: { style: "thin", color: { argb: "000000" } },
          right: { style: "thin", color: { argb: "000000" } },
          bottom: { style: "thin", color: { argb: "000000" } },
        };
      });

      worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
        //if (index % 2 === 0) {
        //  cell.fill = {
        //    type: "pattern",
        //    pattern: "solid",
        //    fgColor: { argb: "E8E8E8" },
        //  };
        //}
        cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
      });
      worksheet.getRow(worksheet.rowCount).height = 25;
      worksheet.getRow(worksheet.rowCount).alignment = {
        vertical: "middle",
        horizontal: "left",
      };
    });

    worksheet.addRow([
      `${t("table.total")}`,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      summary,
    ]);

    worksheet.getRow(worksheet.rowCount).eachCell((cell) => {
      cell.font = { size: 12, bold: true, color: { argb: "FF0000" } };
    });
    worksheet.getRow(worksheet.rowCount).height = 25;

    worksheet.getRow(worksheet.rowCount).alignment = {
      vertical: "middle",
      horizontal: "left",
    };

    // sheet 2
    const worksheet2 = workbook.addWorksheet("Sheet 2");
    worksheet2.getColumn(1).width = 10;
    worksheet2.getColumn(2).width = 25;
    worksheet2.getColumn(3).width = 20;
    worksheet2.getColumn(4).width = 20;
    worksheet2.getColumn(5).width = 20;
    worksheet2.getColumn(6).width = 20;
    worksheet2.getColumn(7).width = 20;
    worksheet2.getColumn(8).width = 20;
    worksheet2.getColumn(9).width = 10;

    //title
    worksheet2.getCell("A1").value = t("title.detailedOvertimeStatistics");
    worksheet2.mergeCells("A1:I1");

    worksheet2.getCell("A1").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet2.getCell("A1").font = {
      size: 20,
      bold: true,
      color: { argb: "FFFFFF" },
    };

    worksheet2.getCell("A1").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet2.getRow(1).height = 30;
    // year
    worksheet2.getCell("A2").value = `${dayjs(
      paginationAgregate?.startDate
    ).format("DD/MM/YYYY")} - ${dayjs(paginationAgregate?.endDate).format(
      "DD/MM/YYYY"
    )}`;
    worksheet2.mergeCells("A2:I2");
    worksheet2.getCell("A2").alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    worksheet2.getCell("A2").font = {
      size: 13,
      bold: false,
      color: { argb: "FFFFFF" },
    };
    worksheet2.getCell("A2").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "4F81BD" },
    };
    worksheet2.getRow(2).height = 20;
    //header

    const headerExceljsSheet2 = ["A", "B", "C", "D", "E", "F", "G", "H", "I"];

    worksheet2.addRow([
      t("table.no"),
      t("table.fullName"),
      t("table.date"),
      t("table.startTime"),
      t("table.endTime"),
      `${t("table.workingTime")}(h)`,
      t("table.dateType"),
      t("table.coefficient"),
      t("table.total"),
    ]);

    _.forEach(headerExceljsSheet2, (i) => {
      worksheet2.getCell(`${i}3`).border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    worksheet2.getRow(worksheet2.rowCount).height = 25;

    worksheet2.getRow(worksheet2.rowCount).eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "B4C6E7" },
      };
      cell.font = { size: 12, bold: true };
    });
    worksheet2.getRow(worksheet2.rowCount).height = 25;

    worksheet2.getRow(worksheet2.rowCount).alignment = {
      vertical: "middle",
      horizontal: "center",
    };

    // data sheets 2

    aggregateList.forEach((item: any, index: number) => {
      let coefficient: any = 0;
      const logsCount = item?.logTime?.length;
      item?.logTime.forEach((log: any) => {
        if (
          log.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
          log.startTime >= checkCurrentimeMorning
        ) {
          coefficient = 1.5;
        }
        if (log.dateType === ENUMS.LOG_TIME_DAY_TYPE.HOLIDAY) {
          coefficient = 3;
        }
        if (log.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND) {
          coefficient = 2;
        }
        if (
          log.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKDAY &&
          log.startTime < checkCurrentimeMorning
        ) {
          coefficient = 2;
        }
        const dateObject = new Date(log?.date);
        const dayOfWeek = dateObject.getDay();
        const newRow = worksheet2.addRow([
          index + 1,
          item?.userData?.fullName,
          log?.date,
          log?.startTime,
          log?.endTime,
          Math.round(log?.workingTime * 1000) / 1000,
          log.dateType === ENUMS.LOG_TIME_DAY_TYPE.WEEKEND
            ? dayOfWeek !== 0
              ? t(`label.weekend`)
              : t(`label.sunday`)
            : t(`label.${log?.dateType}`),
          coefficient,
          Math.round(log?.workingTime * 1000 * coefficient) / 1000,
        ]);

        //_.forEach(headerExceljs, (i) => {
        //  worksheet.getCell(`${i}${key + 4}`).border = {
        //    top: { style: "thin", color: { argb: "000000" } },
        //    left: { style: "thin", color: { argb: "000000" } },
        //    right: { style: "thin", color: { argb: "000000" } },
        //    bottom: { style: "thin", color: { argb: "000000" } },
        //  };
        //});

        newRow.eachCell((cell) => {
          cell.font = { size: 12, bold: false, color: { argb: "233E7C" } };
        });

        newRow.height = 25;
        newRow.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      });

      if (logsCount >= 1) {
        const mergeStartRow = worksheet2.rowCount - logsCount + 1;
        const mergeEndRow = worksheet2.rowCount;
        _.forEach(headerExceljsSheet2, (i) => {
          for (let x = mergeStartRow; x <= mergeEndRow; x++) {
            worksheet2.getCell(`${i}${x}`).border = {
              top: { style: "thin", color: { argb: "000000" } },
              left: { style: "thin", color: { argb: "000000" } },
              right: { style: "thin", color: { argb: "000000" } },
              bottom: { style: "thin", color: { argb: "000000" } },
            };
          }
        });

        worksheet2?.mergeCells(mergeStartRow, 1, mergeEndRow, 1);
        worksheet2?.mergeCells(mergeStartRow, 2, mergeEndRow, 2);
      }
    });

    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), "Overtime-statistics.xlsx");
  }

  return (
    <Button
      size="sm"
      onClick={exportToExcel}
      leftIcon={<Icon as={DownloadIcon} />}
      isDisabled={_.isEmpty(aggregateList)}
      sx={{
        background: "rgba(187, 113, 84)",
        "&:hover": {
          background: "rgba(187, 113, 84,0.5)",
        },
        "&:hover:disabled": {
          background: "rgba(187, 113, 84,0.5)",
        },
        color: "#ffffff",
      }}
    >
      {t("button.export")}
    </Button>
  );
};
